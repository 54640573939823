<template>
  <div class="filter-tag-wrapper">
    <div
      :class="{
        'tag-active':isCheck,
        'disabled' :isDisabled
      }"
      class="filter-tag-item"
      @click="changeFilter"
    >
      {{ filter.name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IFilter } from '#sitis/internal/controllers/filters/models/Filter';

const props = defineProps<{
	filter: IFilter;
	selectedFilters: Record<string, any>;
	isDisabled?: boolean;
}>();

const emits = defineEmits<{
	onChangeFilterVariant: [
		slug: string,
		value: Record<string, string> | null,
		isDisabled?: boolean,
	];
}>();

const isCheck = computed(() => {
  const { slug } = props.filter;

  return Boolean(props.selectedFilters[slug]);
});

const changeFilter = () => {
  const { slug } = props.filter;
  const filterVariantValue = (props.filter?.variants || []).find((t) => Boolean(t.slug === 'da' || t.slug === '1'))?.slug;

  const newSlug: Record<string, string> = { ...props.selectedFilters[slug] };

  if (Object.keys(newSlug).length > 0) {
    emits('onChangeFilterVariant', slug, null);
  } else {
    emits('onChangeFilterVariant', slug, { [filterVariantValue!]: filterVariantValue! });
  }
};

const isDisabled = computed(() => {
  const slug = (props.filter?.variants || []).find((t) => Boolean(t.slug === 'da' || t.slug === '1'))?.value;
  const available = props.filter?.available || [];

  return !available.includes(Number(slug));
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.filter-tag-item {
	padding: 0 13px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	background: var(--white);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 112%; /* 16.5px */
	letter-spacing: -0.3px;
	color: var(--primary-40);
	cursor: pointer;
	border: var(--input-border);
	box-shadow: 0 0 0 0 rgb(0 0 0 / 3%), 0 1px 3px 0 rgb(0 0 0 / 3%), 0 5px 5px 0 rgb(0 0 0 / 3%), 0 12px 6px 0 rgb(0 0 0 / 2%), 0 21px 8px 0 rgb(0 0 0 / 0%), 0 33px 9px 0 rgb(0 0 0 / 0%);

	&.tag-active {
		font-weight: 500;
		color: var(--primary);
		background-color: var(--secondary);
	}

	&.disabled {
		user-select: none;
		pointer-events: none;
		background: #e5e5e5;
		color: #a6a6a6;
	}
}

@include media('sm') {
}
</style>
